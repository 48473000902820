<template>
  <v-form v-model="valid">
    <v-container>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="firstname"
            :rules="nameRules"
            :counter="10"
            label="First name"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field
            v-model="lastname"
            :rules="nameRules"
            :counter="10"
            label="Last name"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="phone"
            :rules="phoneRules"
            label="Phone"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12">
          <v-textarea
            v-model="comments"
            :rules="commentsRules"
            label="Comments"
            required
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row align="center" class="justify-center">
        <v-col
          align-self="center"
          class="justify-center text-center"
          cols="12"
          md="6"
        >
          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="validate"
          >
            Validate
          </v-btn>
        </v-col></v-row
      >
    </v-container>
  </v-form>
</template>


<script>
export default {
  name: "ContactForm",
  data: () => ({
    valid: true,
    firstname: "",
    lastname: "",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => v.length <= 10 || "Name must be less than 10 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    phone: "",
    phoneRules: [(v) => !!v || "Phone is required"],
    comments: "",
    commentsRules: [(v) => !!v || "Comments required"],
  }),
  methods: {
    validate() {
      this.$refs.form.validate();
    },
  },
};
</script>